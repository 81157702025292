<template>
  <div class="body-container">
    <echart-component v-if="roleEnum === 'admin'"></echart-component>
    <div class="pie-wrap" v-else>
    </div>
  </div>
</template>

<script>
import EchartComponent from './echarts'
// 引入 ECharts 主模块
var echarts = require('echarts/lib/echarts');
// 引入柱状图
require('echarts/lib/chart/bar');
// 引入提示框和标题组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
export default {
  components:{
    EchartComponent
  },
  data() {
    return {
      roleEnum:''
    }
  },
  watch : {
    '$route' (to, from) {

      // from 对象中要 router 来源信息.
      // do your want
    }
    // '$route':'getPath'
  },
  beforeRouteEnter (to, from, next) {
    next();
  },
  methods: {
    // getPath(){
    //   console.log(this.$route.path);
    // },
    handleDelete(index, row) {
    },
  },

  mounted(){
    console.log(2)
    // window.location.reload()
    this.roleEnum = sessionStorage.getItem('roleEnum')
    // // this.$route.query.roleEnum,
    console.log(this.roleEnum);
  }
}
</script>

<style scoped>
body{
  background: #cccccc;
}
.body-container{
  height:100%;
  width:100%;
}
.pie-wrap {
  width: 100%;
  height: 100%;
  background: url("../assets/BG.png") no-repeat center 0;
}
.myicon{
  display: block;
  font-size: 32px;
  width: 30%;
  height: 60px;
  float: left;
  line-height: 60px;
  color: #23b7e5;
  text-align: right;
}
.myiconred{
  display: block;
  font-size: 32px;
  width: 30%;
  height: 60px;
  float: left;
  line-height: 60px;
  color: red;
  text-align: right;
}
.icon_tit{
  width: 70%;
  height: 60px;
  float: left;
  color: #23b7e5;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
}
.icon_titred{
  width: 70%;
  height: 60px;
  float: left;
  color: red;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
}
.el-row {
  margin-bottom: 20px;
  height: 150px;
&:last-child{
   margin-bottom: 0;
 }
}
.el-col {
  border-radius: 4px;
  height: 150px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  height: 150px;
  border: 1px solid #cccccc;
  box-shadow: 2px 2px 5px #cccccc;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.boxtitle {
  width: 100%;
  height: 50px;
  color: #23b7e5;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
}
.boxtitlenums{
  width: 100%;
  height: 60px;
}
.boxtitleEch {
  width: 100%;
  height: 30px;
  font-size: revert;
  line-height: 30px;
  padding-left: 10px;
}
.boxtitleEchmath {
  width: 100%;
  height: 30px;
  text-align: center;
}
.boxtitleEchview {
  width: 100%;
  height: 90px;
}
.line-wrap {
  width: 100%;
  height: 100%;
}
/*页面引入的官方element ui table表格宽度调整*/
.el-table__header tr,
.el-table__header th {
  padding: 0;
  height: 30px;
}
.el-table__body tr,
.el-table__body td {
  padding: 0;
  height: 30px;
}
.qrcode{
  width: 50%;
  height: 100%;
  float: left;
}
.qrcodeimg{
  width: 100%;
  height: 80%;
  position: relative;
}
.qrcodetitle{
  width: 100%;
  height: 20%;
  text-align: center;
}
</style>
